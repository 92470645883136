.review {
    background-color: #c1c1c136;
    height: 195px;
    /* width: 1440px; */
    justify-content: center;
    display: flex;
}

.review .review1 {
    align-items: center;
    display: inline-flex;
    gap: 81px;
    justify-content: center;
    /* left: 83px; */
    padding: 16px 80px;
    position: relative;
    /* top: 46px; */
}

.review .review-wrapper {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 10px;
    padding: 10px 0px;
    position: relative;
}

.review .review-2 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 24px;
    position: relative;
}

.review .review-3 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    position: relative;
}

.review .text-wrapper {
    color: #f14949;
    font-family: "Rubik-Medium", Helvetica;
    font-size: 36px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
}

.review .add {
    height: 40px;
    position: relative;
    width: 40px;
}

.review .text-wrapper-2 {
    color: #000000;
    font-family: "Rubik-Medium", Helvetica;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 16px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
}

.review .line {
    /* height: 70px; */
    object-fit: cover;
    position: relative;
    width: 1px;
    height: 0px;
}

.review .img {
    flex: 0 0 auto;
    position: relative;
}

.review .review-wrapper {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 10px;
    padding: 10px 0px;
    position: relative;
}

.review .review-4 {
    align-items: center;
    display: flex;
    gap: 24px;
    justify-content: center;
    position: relative;
    width: 265px;
}

.review .location-city {
    height: 48px;
    position: relative;
    width: 48px;
}

@media (max-width: 950px) {
.review{
    height: unset !important;
   
}
.review1{
    flex-direction: column;
    gap: 20px !important;
}
.review-2{
    flex-direction: row !important;
}
.review-3{
    flex: unset !important;;
}

}