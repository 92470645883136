.compare {
    height: 769px;
    /* position: relative; */
    /* width: 1438px; */
    display: flex;
    justify-content: center;
    background-color: #f7f6f3;
    flex-direction: column;
}

.compare .div {
    align-items: center;
    display: inline-flex;
    flex-direction: column;
    gap: 16px;
    left: 349px;
    /* position: absolute; */
    top: 120px;
}

.compare .building-a-home {
    color: transparent;
    font-family: "Montserrat-Bold", Helvetica;
    font-size: 28px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1px;
    position: relative;
    text-align: center;
    width: fit-content;
}

.compare .text-wrapper {
    color: #404040;
    font-weight: 700;
}

.compare .span {
    color: #f14949;
    font-family: "Montserrat-MediumItalic", Helvetica;
    font-style: italic;
    font-weight: 500;
}

.compare .p {
    color: #050505;
    font-family: "Montserrat-Regular", Helvetica;
    font-size: 18px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    text-align: center;
    width: fit-content;
}

.compare .div-2 {
    align-items: flex-start;
    display: inline-flex;
    gap: 46px;
    left: 164px;
    /* position: absolute; */
    top: 270px;
    justify-content: center;
}

.compare .div-3 {
    background-color: #eeeeee;
    height: 480px;
    /* position: relative; */
    width: 531px;
    justify-content: center;
    display: flex;
    flex-direction: column;
}

.compare .div-4 {
    align-items: center;
    display: inline-flex;
    gap: 6px;
    justify-content: center;
    left: 191px;
    /* position: absolute; */
    top: 48px;
}

.compare .product {
    color: #000000;
    font-family: "Montserrat-ExtraBold", Helvetica;
    font-size: 20px;
    font-weight: 800;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
}

.compare .img {
    height: 24px;
    position: relative;
    width: 24px;
}

.compare .div-5 {
    align-items: flex-start;
    display: inline-flex;
    flex-direction: column;
    gap: 24px;
    left: 60px;
    /* position: absolute; */
    top: 120px;
    margin-left: 60px;
}

.compare .div-6 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 12px;
    /* position: relative; */
}

.compare .text-wrapper-2 {
    color: #000000;
    font-family: "Montserrat-SemiBold", Helvetica;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    width: fit-content;
}

.compare .text-wrapper-3 {
    color: #000000;
    font-family: "Montserrat-SemiBold", Helvetica;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1px;
    position: relative;
    width: fit-content;
}

.compare .div-7 {
    background: linear-gradient(180deg, rgb(203, 249, 239) 7.54%, rgb(200, 223, 226) 58.06%, rgb(199, 214, 221) 97.21%);
    height: 480px;
    position: relative;
    width: 531px;
    justify-content: center;
    display: flex;
    flex-direction: column;
}

.compare .div-8 {
    align-items: center;
    display: inline-flex;
    gap: 6px;
    justify-content: center;
    left: 191px;
    /* position: absolute; */
    top: 53px;
}

.compare .product-2 {
    color: transparent;
    font-family: "Montserrat-ExtraBold", Helvetica;
    font-size: 20px;
    font-weight: 800;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1px;
    /* position: relative; */
    white-space: nowrap;
    width: fit-content;
}

.compare .text-wrapper-4 {
    color: #f14949;
}

.compare .text-wrapper-5 {
    color: #f86800;
}

.compare .text-wrapper-6 {
    color: #000000;
}

.compare .div-9 {
    align-items: flex-start;
    display: inline-flex;
    flex-direction: column;
    gap: 24px;
    left: 60px;
    /* position: absolute; */
    top: 125px;
    margin-left: 60px;
}

.compare .text-wrapper-7 {
    color: #000000;
    font-family: "Montserrat-Bold", Helvetica;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    width: fit-content;
}

.compare .text-wrapper-8 {
    color: #000000;
    font-family: "Montserrat-Bold", Helvetica;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1px;
    position: relative;
    width: fit-content;
}


@media (max-width: 950px) {
    .div{
        margin-top: 50px;
        width: 90%;
        margin-left: 5%;
    }
    .compare {
        height: unset;
    }
    .div-2{
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .div-3{
        width: 100% !important;
        
    }
    .div-7{
        width: 100% !important;
        height: unset !important;
        padding-top: 20px;
        padding-bottom: 20px;
    }
    .div-9{
        margin-left: 8.5rem !important;
        justify-content: center !important;
    }
    .div-5{
        margin-left: 8.5rem !important;
        justify-content: center !important;
    }
   
}

@media (max-width: 500px) {
    .div-9{
        margin-left: 2rem !important;
        justify-content: center !important;
    }
    .div-5{
        margin-left: 2rem !important;
        justify-content: center !important;
    }
}
