.timeline-body{
    margin: 0;
    padding: 0;
    font-family: 'Poppins' ,sans-serif;
    box-sizing: border-box;
 background: #F7F6F3;
 padding-top: 80px;
    }
    .timeline{
    position: relative;
    max-width: 1200px;
    margin: auto;
    padding-bottom: 100px;
    margin-top: 40px;
    }
    .containert{
    padding: 10px 50px;
    position: relative;
    width: 41.5%;
    /* background: rgba(0, 0, 0, 0.3); */
    animation: movedown 1s linear forwards;
    opacity: 0;
    z-index: 1;
    }
    
    
    @keyframes movedown {
        0%{
    opacity: 1;
    transform: translateY(-30px);
        }
        100%{
    opacity: 1;
    transform: translateY(0px);
    }
    }
    .containert:nth-child(2){
    animation-delay:1s;
    }
    .containert:nth-child(3){
        animation-delay:2s;
        }
        .containert:nth-child(4){
            animation-delay:3s;
            }
            .containert:nth-child(5){
                animation-delay:4s;
                }
      
    
    
    .text-box{
    padding: 2px 30px;
    background: rgba(255, 255, 255, 0.8);
    position: relative;
    border-radius: 6px;
    font-size: 15px;
    }
    .left-container{
        left :0
    }
    /* .left-container h2{
        text-align: right;
    } */
    .right-container{
    
        left :50%;
    }
    
    .containert img{
        position: absolute;
        width: 40px;
        border-radius: 50%;
        right:-20px;
        top: 32px;
        z-index: 100;
    }
        .right-container img{
            left :  -20px;
        }
        .timeline::after{
            content: ' ';
            position: absolute;
            width: 6px;
            height: 100%;
            background: #fff;
            top: 46px;
            left: 50%;
            margin-left : -3px;
            /* z-index: ; */
            animation: moveline 6s linear forwards;
        }
    
       
    @keyframes moveline {
        0%{
            height: 0;
        }
        100%{
            height: 70%;
        }
    }
    
        .text-box h2{
            font-weight: 600;
        }
            .text-box small{
            display: inline-block;
            margin-bottom: 15px;
            }
    
            .left-container-arrow{
                height: 0; 
                width: 0; 
                position: absolute;
                top: 28px;
                z-index: 1;
                border-top: 15px solid transparent;
                border-bottom: 15px solid transparent;
                border-left: 15px solid rgba(255, 255, 255, 0.8);
                right: -15px;
            }
            .right-container-arrow{
                height: 0; 
                width: 0; 
                position: absolute;
                top: 28px;
                z-index: 1;
                border-top: 15px solid transparent;
                border-bottom: 15px solid transparent;
                border-right: 15px solid rgba(255, 255, 255, 0.8);
                left: -15px;
            }
           
    
            @media screen and (max-width:600px)
    {
        .timeline{
    margin: 50px auto;
        }
    .timeline::after{
    left: 31px;
    }
    .containert{
    width: 72%;
    padding-left: 80px;
    padding-right: 25px;
    }
    .text-box{
        font-size: 13px;}
    
        .text-box small{
        margin-bottom: 10px;
        }
        .right-container{
        left: 0;
        }
        .left-container img, .right-container img{
        left: 10px;
        }
        .left-container-arrow, .right-container-arrow{
         border-right: 15px solid rgba(255, 255, 255, 0.8);
         border-left: 0;
         left: -14px;
        }
    
    }
    
    .pro-container{
        background-color: white;
        border: 2px solid #971eff;
    }
    .pro-container h2{
        font-size: 18px;
    }