.bg-color{
    background: linear-gradient(109deg, rgba(224, 236, 245, 0.30) 44.06%, rgba(0, 246, 187, 0.30) 126.76%);
    height: 82vh;
}
.container {
    height: 100%;
    padding: 40px;
    margin-left: 40px;
    margin-right: 40px;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    gap: 40px;
  }
  
  .container img {
    /* width: 100%; */
    height: 500px;
  }
  
  .hero-text {
    width: 80%;
  }
  
  .hero-text h1 {
    font-size: 3.3rem;
    margin-bottom: 12px;
    color: black;
    /* font-weight: lighter; */
  }
  
  .hero-text p {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 12px;
  }
  
  /* button {
    padding: 1em 2.1em 1.1em;
    border-radius: 4px;
    margin: 8px;
    border: none;
    background-color: #e2e273;
    color: #000000;
    font-weight: 800;
    font-size: 0.85em;
    text-transform: uppercase;
    text-align: center;
    box-shadow: 0em -0.2rem 0em #e2e273 inset;
    transition: color 3s ease-in-out;
  }
  
  button:hover {
    background-color: transparent;
    color: #8b4513;
    border: 3px solid #e2e273;
    cursor: pointer;
  } */
  .button {
    height: 50px;
    width: 200px;
    position: relative;
    background-color: transparent;
    cursor: pointer;
    border: 2px solid #252525;
    overflow: hidden;
    /* border-radius: 30px; */
    color: #333;
    transition: all 0.5s ease-in-out;
    border: 1px solid #4A4A4A;
  }
  
  .btn-txt {
    z-index: 1;
    font-weight: 800;
    letter-spacing: 4px;
  }
  
  .type1::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    transition: all 0.5s ease-in-out;
    background-color: #F14A4A;
    /* border-radius: 5px; */
    visibility: hidden;
    height: 10px;
    width: 10px;
    z-index: -1;
  }
  
  .button:hover {
    /* box-shadow: 1px 1px 200px #252525; */
    color: #fff;
    border: none;
  }
  
  .type1:hover::after {
    visibility: visible;
    transform: scale(100) translateX(2px);
  }
  .hero-footer{
    background: #FAFAFA;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    padding: 0.75rem 0rem;
    justify-content: center;
    align-items: center;
  }
  .hero-footer-innerdiv{
    display: flex;
width: 18.875rem;
padding: 0.625rem 0rem;
flex-direction: row;
justify-content: center;
align-items: center;
gap: 0.625rem;
flex-shrink: 0;
  }
  .below{
    display: none;
  }
  .type {
    --d:2s; /* duration */
    display:inline-block;
    color: #F14A4A;
    vertical-align: bottom;
    /* font-size: 60px; */
  }
  
  .type > span {
    display:grid;
    overflow: hidden;
    height:1.2em;
  }
  
  .type span span {
    width:0%;
    max-width:max-content;
    overflow: hidden;
    /* margin-top: -2px; */
    height: 51px;
    /* height:inherit; */
    word-break:break-all;
    animation:
      c 0.5s infinite steps(1),  
      t var(--d) linear infinite alternate,
      m calc(var(--d)*2*var(--n)) steps(var(--n)) infinite;
  }
  .type span span:before {
    content:" ";
    display:inline-block;
  }
  
  @keyframes t{
    90%,100% {width:100%}
  }
  
  @keyframes c{
    0%,100%{box-shadow:5px 0 0 #0000}
    50%    {box-shadow:5px 0 0 #F14A4A  }
  }
  
  @keyframes m{
    100% {transform:translateY(calc(-100%*var(--n)))}
  }

  /* Media queries */
    
  @media (max-width: 1250px) {
    .container {
      height: 100%;
      padding: 40px;
      /* margin-left: 40px; */
      margin-right: 40px;
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
      align-items: center;
      /* gap: 40px; */
    }
    
    .container img {
      /* width: 100%; */
      height: 400px;
      margin-right: -120px;
    }
    .hero-text {
      width: 70%;
    }
    
    .hero-text h1 {
      font-size: 2.3rem;
      margin-bottom: 12px;
      color: black;
      /* font-weight: lighter; */
    }
    
    .hero-text p {
      font-size: 2rem;
      font-weight: bold;
      margin-bottom: 12px;
    }
  }
  
  @media (max-width: 950px) {
    .container {
       height: fit-content;
      margin-top: 10px;
      flex-direction: column;
    }
    .bg-color{
        height: fit-content;
    }
    .hero-text {
      width: 100%;
      text-align: center;
    }
    .hero-text h1 {
      font-size: 3rem;
    }
    .pointsers{
      margin-right: unset !important;
      width: 90% !important;
      height: auto !important;
    }
  }
  
  @media (max-width: 778px) {
    .bg-color{
      /* height: 92vh; */
padding-bottom: 40px;
    }
    .container {
    
    }
    
    .container img {
      height: 280px;
      margin-right: unset;
      margin-top: -10px;
    }
    .hero-text {
     width: 90%;
     margin-top: -20px;
    }
    
    .hero-text p {
        font-size: 1.2rem;
      /* margin-top: -8px; */
    }
    .hero-text h1 {
      font-size: 1.5rem;
      width: 245px;
      margin-left: -22px;
      margin-bottom: 0px;
    }
    .pointsers{
      display: none;
     
    }
    .below{
      display: block;
      width: 96% !important;
      margin-left: 2%;
    }
    .hero-footer-innerdiv {
      display: flex;
      width: 18.875rem;
      padding: 0.625rem 0rem;
      flex-direction: row;
      justify-content: unset;
      align-items: center;
      gap: 0.625rem;
      flex-shrink: 0;
      margin-left: 35%;
  }
  }


 