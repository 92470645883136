footer{
    /* position: absolute; */
    bottom: 0;
    left: 0;
    right: 0;
    /* background: #111; */
    background: #F14A4A;
    height: auto;
    width: 100%;

    padding-top: 40px;
    color: #fff;
}
.footer-content{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
}
.footer-content h3{
    font-size: 2.1rem;
    font-weight: 500;
    text-transform: capitalize;
    line-height: 3rem;
}
.footer-content p{
    max-width: 500px;
    margin: 10px auto;
    line-height: 28px;
    font-size: 14px;
    color: white;
}
.socials{
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 1rem 0 3rem 0;
}
.socials li{
    margin: 0 10px;
}
.socials a{
    text-decoration: none;
    color: #fff;
    /* border: 1.1px solid white; */
    padding: 5px;

    border-radius: 50%;

}
.socials a i{
    font-size: 1.1rem;
    width: 20px;


    transition: color .4s ease;

}
.socials a:hover i{
    color: aqua;
}

.footer-bottom{
    background: #F14A4A;;
    /* width: 100vw; */
    padding: 20px;
    padding-bottom: 36px;
    height: 50px;
/* padding-bottom: 40px; */
    text-align: center;
}
.footer-bottom p{
float: left;
    font-size: 14px;
    word-spacing: 2px;
    text-transform: capitalize;
}
.footer-bottom p a{
  color:#44bae8;
  font-size: 16px;
  text-decoration: none;
}
.footer-bottom span{
    text-transform: uppercase;
    opacity: .4;
    font-weight: 200;
}
.footer-menu{
  float: right;

}
.footer-menu ul{
  display: flex;
}
.footer-menu ul li{
padding-right: 10px;
display: block;
}
.footer-menu ul li a{
  color: #cfd2d6;
  text-decoration: none;
}
.footer-menu ul li a:hover{
  color: #27bcda;
}

@media (max-width:500px) {
footer{
    /* padding: 40px; */
    /* width: ; */
    padding-bottom: 20px;
}
.footer-content{
    width: 90vw;
    margin-left: 5vw;
}
.footer-menu ul{
  display: flex;
  margin-top: 10px;
  margin-bottom: 20px;
}
.footer-bottom {
    display: flex;
    flex-direction: column;
}
.footer-menu {
    float: unset ;
    justify-content: center;
    display: flex;
    margin-left: -37px;
    padding-bottom: 10px;
}
}