.faq-wrapper {
  width: 100%;
  height: 100%;
  background: #f7f6f3;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 12rem 0rem;
}

.faq-accordion {
  width: 88%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.faq-item:nth-child(2) {
  border-top: 0px;
}

.faq-accordion>h1 {
  font-family: DM Sans;
  font-size: 36px;
  color: var(--faq-text-color);
  padding-bottom: 4.5rem;
}

.faq-item {
  width: 100%;
  /* border-top: 1px solid #170F49; */
  /* padding: 1rem 0rem; */
  border-bottom: 1px solid;
}

.faq-item-title {
  cursor: pointer;
}

.faq-item-title>* {
  font-family: DM Sans;
  font-size: 22px;
  line-height: 25px;
  color: var(--faq-text-color);
}

.faq-item-content p {
  font-family: DM Sans;
  font-size: 16px;
  color: var(--faq-text-color);
  padding-bottom: 2rem;
}

.faq-item-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.faq-item-content {
  max-height: 0;
  overflow: hidden;
}

.faq-item-content.faq-item-show {
  max-height: 9999px;
  transition: all 1s cubic-bezier(0, 1, 0, 1);
}

.faq-item-show {
  padding-bottom: 2rem;
}

.faq-wrapper>button {
  margin-top: 10px;

  color: var(--faq-viewmore-text);
  font-size: 18px;
  font-family: primaryMedium;
  cursor: pointer;
  text-align: center;

  position: relative;
  padding: 1.7rem 3.6rem;
}

.faq-wrapper>button::before {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  border-radius: 8px;

  padding: 3px;
  /*This is actually the border-width*/

  background: linear-gradient(45deg, var(--faq-btn-gradient-color1), var(--faq-btn-gradient-color2));
  -webkit-mask:
    linear-gradient(#fff 0 0) content-box,
    linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
}

@media (max-width: 900px) {
  .faq-wrapper {
    padding: 4rem 0rem;
  }

  .faq-item {
    padding-top: 0rem;
    padding-bottom: 0rem;
  }

  .faq-accordion>h1 {
    font-size: 24px;
    text-align: center;
    padding-bottom: 3.2rem;
  }

  .faq-item-title>h2 {
    font-size: 14px;
    line-height: 16px;
    max-width: 77%;
  }

  .faq-item-content>p {
    font-size: 12px;
  }

  .faq-wrapper>button {
    padding: .8rem 2.4rem;
    font-size: 14px;
    border-radius: 8px;
  }

  .faq-wrapper>button::before {
    padding: 1px 1.5px;
    /*This is actually the border-width*/
  }
}