.popup{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    /* display: none; */
    display: block;
    z-index: 10;
    background-color: white;
    /* border: 4px solid #f79663; */
    border-radius: 22px;
   
  }
  .contentBox{
    position: relative;
    min-width: 720px;
    min-height: 500px;
    background: #fff;
    border-radius: 20px;
    display: flex;
    box-shadow: 0 5px 5px rgba(0,0,o,0.1);
  }
  .contentBox .imgBx{
  position: relative;
  width: 393px;
  height: 554px;
  /* //margin-top: 40px; */
  display: flex;
  background-image: url('../Images/popup.png');
  justify-content: center;
  align-items: center;
  }
  /* .contentBox .imgBx::before{
    content: '';
    position: absolute;
    height: 200px;
    width: 200px;
    background: #e7ffe0;
    border-radius: 50%;
  } */
  
  .contentBox .imgBx img{
    position: relative;
    max-width:300px;
    z-index: 1;
  }
  
  .contentBox .content{
    position: relative;
    width: 415px;
    height: 554px;
    justify-content: center;
    word-break: break-all;
    align-items: center;
    display: flex;
  }
  .contentBox .content h3{
  color: #333;
  Line-height: 1;
  font-weight: 300; 
  font-size: 2em;
  }
  
  .contentBox.content h2{
  font-size: 4em;
  color: #ff4d54;
  }
  .contentBox .content h2 span{
  
  color:#333;
  font-size: 0.75em;
  text-transform: uppercase;
  }
  
  .contentBox .content p{
  font-weight: 300;
  
  }
  
  .contentBox .content a{
  display:inline-block;
  padding: 10px 20px;
  /* background: #ff4d54; */
  color: black;
  margin-top: 15px;
  text-decoration: none;
  border-radius: 10px;
  }
  .close1{
    position: absolute;
    top: 20px;
    right: 20px;
    height: 40px;
    width: 40px;
    /* background: url('../img/close.png'); */
    background-size: cover;
    z-index: 10;
    cursor: pointer;
  }

  .contentBox .content h3{
    font-weight: bold;
  }
  .contentBox .content p{
color: black;
font-size: large;
  }

  .login-box {
    /* position: absolute;
    top: 50%;

    left: 50%;
    width: 400px; */
    padding: 40px;
    /* margin-top: 190px; */
    /* transform: translate(-50%, -50%); */
    /* background: rgba(0,0,0,.5); */
    box-sizing: border-box;
    /* box-shadow: 0 15px 25px rgba(0,0,0,.6); */
    border-radius: 10px;
  }
  
  .login-box h2 {
    margin: 0 0 30px;
    padding: 0;
    color: black;
    /* text-align: center; */
  }
  
  .login-box .user-box {
    position: relative;
  }
  
  .login-box .user-box input {
    width: 20.9375rem;
    height: 1.6rem;
    padding: 10px 0;
    font-size: 16px;
    color:black;
    margin-bottom: 30px;
    border: none;
    /* border-bottom: 1px solid black; */
    outline: none;
    background: #F5F7FF;
    margin-top: 10px;
  }
  .login-box .user-box label {
    position: absolute;
    top: 17px;
    left: 15px;
    padding: 10px 0;
    font-size: 16px;
    color: #fff;
    pointer-events: none;
    transition: .5s;
    color: #7E7E7E;

font-family: Montserrat;
font-size: 0.875rem;
font-style: normal;
font-weight: 500;
line-height: normal;
text-transform: capitalize;
  }
  /* .user-box label{
    top: -20px;
    left: 0;
    color: #03e9f4;
    font-size: 12px;
  }
   */
   .login-box .user-box input:focus  {
    border-bottom: 1px solid black;
   }
  .login-box .user-box input:focus ~ label,
  .login-box .user-box input:valid ~ label {
    top: -20px;
    left: 0;
    /* color: black;
    font-size: 12px; */
  }
  .login-box .user-box textarea:focus ~ label,
  .login-box .user-box textarea:valid ~ label {
    top: -20px;
    left: 0;
    /* color: black;
    font-size: 12px; */
  }
  .login-box .user-box select:focus ~ label,
  .login-box .user-box select:valid ~ label {
    top: -20px;
    left: 0;
    /* color: black;
    font-size: 12px; */
  }
  .login-box form button {
    display: flex;
width: 20.9375rem;
padding: 0.625rem 1rem;
justify-content: center;
align-items: center;
gap: 0.625rem;
background: #F14A4A;
border: none;
font-size: 20px;
font-weight: bold;
color:white
  }
  
  .login-box button:hover {
    background: #f79663;
    color: black;
   
  }
  

  .sel{
    margin-top: 16px;
    width: 108px;
    height: 30px;
    border-radius: 8px;
  }
  .textt{
    margin-top: 13px;
    height: 75px;
    width: 200px;
  }
  .wrapper-1{
    display: flex;
  flex-direction: column;
  }
  .wrapper-2{
    padding :30px;
    text-align:center;
  }
  h1{
      font-family: 'Kaushan Script', cursive;
    font-size:4em;
    letter-spacing:3px;
    color:#5892FF ;
    margin:0;
    margin-bottom:20px;
  }
  .wrapper-2 p{
    margin:0;
    font-size:1.3em;
    color:#aaa;
    font-family: 'Source Sans Pro', sans-serif;
    letter-spacing:1px;
  }
  @media(max-width: 900px){

    .login-box{
      /* margin-left: -103px;
      margin-top: 24px; */
    }
    .contentBox{
    min-width: 330px;
    height: auto;
    flex-direction: column;
    }
    
    .contentBox .imgBx
    {
    display: none;
    }
    .contentBox .imgBx::before{
    background: #fff;
    }
    
    .contentBox .content{
    height: auto;
    text-align: center;
    padding: 20px;
    padding-top: 0;
    width: 308px;
    }
    .close{
      top: 10px;
      right: 10px;
    }
    .login-box .user-box input {
      width: 17.9375rem;
      height: 1.6rem;
      padding: 10px 0;
      font-size: 16px;
      color:black;
      margin-bottom: 30px;
      border: none;
      /* border-bottom: 1px solid black; */
      outline: none;
      background: #F5F7FF;
      margin-top: 10px;
    }
    .login-box .user-box input:focus ~ label,
    .login-box .user-box input:valid ~ label {
      top: -20px;
      left: 2px;
      /* color: black;
      font-size: 12px; */
    }
    .login-box .user-box textarea:focus ~ label,
    .login-box .user-box textarea:valid ~ label {
      top: -20px;
      left: 2px;
      /* color: black;
      font-size: 12px; */
    }
    .login-box .user-box select:focus ~ label,
    .login-box .user-box select:valid ~ label {
      top: -20px;
      left: 2px;
      /* color: black;
      font-size: 12px; */
    }
    .login-box form button {
      display: flex;
  width: 17.9375rem;
  padding: 0.625rem 1rem;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  background: #F14A4A;
  border: none;
  font-size: 20px;
  font-weight: bold;
  color: white;
    }
    
    }