
.image-gallery {
  width: min(90%, 100rem);
  margin: 0 auto;
  height: 100vh;
  display: grid;
  place-items: center;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  letter-spacing: 0.5px;
}

.image-gallery img {
  width: 100%;
  display: block;
  height: 100%;
  object-fit: cover;
}

.image-gallery h2 {
  font-size: clamp(1rem, 0.8750rem + 0.6250vw, 1.5rem);
  line-height: 1.3;
  font-weight: 700;
}

.image-gallery section {
  display: grid;
  grid-template-columns: 25% 30% 15% 25%;
  gap: 15px;
  place-content: center;
  grid-template-rows: 50% 50%;
  height: 80vh;
  min-height: 460px;
  padding: max(2vh, 1.5rem);
}
@media screen and (max-width: 690px) {
    .image-gallery section {
    height: 65vh;
  }
}
@media screen and (max-width: 470px) {
    .image-gallery section {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, 35%);
  }
}
.image-gallery section .card {
  border-radius: 25px;
  box-shadow: -2px 4px 15px rgba(0, 0, 0, 0.26);
}
@media screen and (max-width: 470px) {
    .image-gallery section .card {
    grid-column: span 1;
  }
}
.image-gallery section .card:nth-child(2) {
  grid-column: 2/3;
  grid-row: span 2;
}
@media screen and (max-width: 690px) {
    .image-gallery section .card:nth-child(2) {
    grid-column: span 1;
    grid-row: span 1;
  }
}
.image-gallery section .card:nth-child(3) {
  grid-column: span 2;
}
@media screen and (max-width: 690px) {
    .image-gallery section .card:nth-child(3) {
    grid-column: 2/4;
    grid-row: 1/2;
  }
}
@media screen and (max-width: 690px) {
    .image-gallery section .card:nth-child(6) {
    grid-column: 2/4;
    grid-row: 2/3;
  }
}
@media screen and (max-width: 470px) {
    .image-gallery section .card:nth-child(5) {
    grid-column: span 2;
  }
}
.image-gallery section .card p {
  font-size: clamp(0.9rem, 0.8750rem + 0.1250vw, 1rem);
  line-height: 1.4;
}
.image-gallery section .card img {
  border-radius: 25px;
}
.image-gallery section .card .card__img {
  position: relative;
  height: 100%;
}
.image-gallery section .card .card__img .card__overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  content: "";
  color: #fff;
  padding: clamp(0.938rem,5vw,1.563rem);
  /* background: #02022e; */
  /* background: linear-gradient(0deg, rgba(0, 0, 0, 0.57) 0%, rgba(255, 255, 255, 0) 100%); */
  width: 100%;
  height: 100%;
  border-radius: 25px;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
}
.image-gallery section .card .card__img span {
  position: absolute;
  top: 25px;
  left: min(2vmax, 1.563rem);
  color: #ff7b29;
  background: #fff;
  border-radius: 50px;
  padding: 2px 8px 2px 6px;
  display: flex;
  box-shadow: 0px 1px 20px #0000002b;
}
@media screen and (max-width: 690px) {
  .image-gallery{
    margin-bottom: 100px;
  }
  .image-gallery section .card .card__img span {
    top: 20px;
  }
}
@media screen and (max-width: 470px) {
  .image-gallery section .card .card__img span {
    top: 15px;
  }
}
.image-gallery section .card .card__img span svg {
  fill: #ff7b29;
  width: 20px;
  margin-right: 2px;
}

.slider {
  display: block;
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: absolute;
}

.slider>* {
  position: absolute;
  display: block;
  width: 300px;
  height: 100%;
  animation: slide 12s infinite;
}

.slide:nth-child(1) {
  left: 0%;
  animation-delay: -1s;
  background-image: url('../Images/gallery/Frame\ 92.png');
  background-size: cover;
  background-position: center;
}

.slide{
  width: 100%;
  aspect-ratio: 16/9;
  height: auto;
}

.slide:nth-child(2) {
  left: 100%;
  animation-delay: 2s;
  background-image: url('../Images/gallery/Frame\ 93.png');
  background-size: cover;
  background-position: center;
}

.slide:nth-child(3) {
  left: 100%;
  animation-delay: 5s;
  background-image: url('../Images/gallery/Frame\ 94.png');
  background-size: cover;
  background-position: center;
}

.slide:nth-child(4) {
  left: 100%;
  animation-delay: 8s;
  background-image: url('../Images/gallery/Frame\ 96.png');
  background-size: cover;
  background-position: center;
}

.slide p {
  font-family: sans-serif;
  font-size: 70px;
  text-align: center;
  display: inline-block;
  width: 100%;
  color: #fff;
  display: none;
}

@keyframes slide {
  0% {
      left: 100%;
      width: 100%;
      opacity: 1;
  }

  5% {
      left: 0%;
  }

  25% {
      left: 0%;
  }

  30% {
      left: -100%;
      width: 100%;
      opacity: 1;
  }

  30.0001% {
      left: -100%;
      width: 0%;
      opacity: 0;
  }

  100% {
      left: 100%;
      width: 100%;
      opacity: 0;
  }
}

.imagephone{
  width: 100%;
  height: 600px;
  padding-top: 120px !important;
  overflow: hidden !important;
}


@media (max-width: 700px){
  .imagephone{
    height: 500px !important;
  }
}