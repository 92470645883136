@import url('https://fonts.googleapis.com/css2?family=DM+Sans:opsz,wght@9..40,100;9..40,200;9..40,300;9..40,400;9..40,500;9..40,600;9..40,700;9..40,800;9..40,900&display=swap');
/* html { width: 100%; height: 100%; }
body { text-align: center; margin: 0px; padding: 0px; height: 100%; color: #fff; font-family: sans-serif;
background: linear-gradient(-45deg, #EE7752, #E73C7E, #23A6D5, #23D5AB);
background-size: 400% 400%;
-webkit-animation: Gradient 15s ease infinite;
-moz-animation: Gradient 15s ease infinite;
animation: Gradient 15s ease infinite;}
.vh { height: 100vh; align-items: center; display: flex; }
.vh > div { width: 100%; text-align: center; vertical-align: middle; }
img { max-width: 100%; }
.wrap {text-align: center;}
.wrap h1 {font-size: 30px;font-weight: 700;margin: 0 0 90px;}
.wrap h2 {font-size: 24px;font-weight: 400;line-height: 1.6;margin: 0 0 80px;}
@-webkit-keyframes Gradient {
0% {background-position: 0% 50%}
50% {background-position: 100% 50%}
100% {background-position: 0% 50%}
}
@-moz-keyframes Gradient {
0% {background-position: 0% 50%}
50% {background-position: 100% 50%}
100% {background-position: 0% 50%}
}
@keyframes Gradient {
0% {background-position: 0% 50%}
50% {background-position: 100% 50%}
100% {background-position: 0% 50%}
} */
.responsive {
    width: 100%;
    height: auto;
  }

  html *
{
  /* color: #404040; */

  font-family: "DM Sans", "sans-serif" !important;
}

.banner {
  min-width: 60vw;
  text-align: center;
  background: transparent;
  color: white;
  padding: 1rem;
  cursor: pointer;
  font-family: "Libre Baskerville";
  font-size: 22px;
  font-weight: 400;
}
.banner a {
  color: white;
}
.banner:before {
  content: "";
  position: absolute;
  background: rgb(241, 74, 74);
  box-shadow: -0.75rem 0.75rem 0px rgba(241, 74, 74, 0.5);
  top: 0;
  right: 0;
  left: 0.75rem;
  bottom: 0.75rem;
  transition: all 0.2s;
}
.banner:after {
  content: "";
  position: absolute;
  top: 0.75rem;
  right: 0.75rem;
  left: 0.75rem;
  bottom: 0.75rem;
  background: #f14a4a;
  z-index: -1;
  opacity: 0.8;
}
.banner * {
  position: relative;
  z-index: 2;
}
.banner:hover:before {
  top: 0.75rem;
  bottom: 0.75rem;
  left: 0.75rem;
  right: 0.75rem;
  background: #f14a4a;
  box-shadow: 0px 0px 0px #f14a4a;
}

.banner {
  /* position: absolute; */
  top: 50%;
  width: 90%;
  margin-left: 5%;
  left: 50%;
  transform: translate(0%, 50%);
}

@media (max-width: 700px){
  .banner {
    /* position: absolute; */
    top: 50%;
    width: 90%;
    margin-left: 5%;
    left: 50%;
    transform: translate(-4%, 50%);
  }
}