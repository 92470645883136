.frame-credo {
    background-color: #ffffff;
    /* height: 694px; */
    width: 100%;
  }
  
  .frame-credo .credo {
    /* height: 525px; */
    /* left: 80px; */
    position: relative;
    top: 120px;
    width: 90%;
    margin-left: 4%;
  }
  
  .frame-credo .credo-2 {
    align-items: center;
    display: inline-flex;
    flex-direction: column;
    /* gap: px; */
    /* left: 326px; */
    width: 90%;
    text-align: center;
    margin-left: 5%;
    /* position: absolute; */
    top: 0;
  }
  
  .frame-credo .reimagine-home {
    color: transparent;
    font-family: "Montserrat-Bold", Helvetica;
    font-size: 28px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1px;
    position: relative;
    width: fit-content;
  }
  
  .frame-credo .text-wrapper {
    color: #f14949;
  }
  
  .frame-credo .span {
    color: #404040;
  }
  
  .frame-credo .a-step-by-step {
    color: #050505;
    font-family: "Montserrat-Regular", Helvetica;
    font-size: 18px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    text-align: center;
    width: 90%;

  }
  
  .frame-credo .surface {
    align-items: center;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    left: -1px;
    /* position: absolute; */
    top: 146px;
    width: 100%;
  }
  
  .frame-credo .list {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 20px;
    /* height: 321px; */
    position: relative;
    width: 100%;
  }
  
  .frame-credo .row {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    gap: 20px;
    /* height: 321px; */
    position: relative;
    width: 100%;
    flex-wrap: wrap;
  }
  
  .frame-credo .block {
    align-items: flex-start;
    display: flex;
    flex: 1;
    flex-direction: column;
    flex-grow: 1;
    gap: 32px;
    height: 321px;
    position: relative;
  }
  
  .frame-credo .item-surface {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 1;
    flex-direction: column;
    flex-grow: 1;
    gap: 32px;
    position: relative;
    width: 100%;
  }
  
  .frame-credo .image {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    gap: 10px;
    justify-content: center;
    padding: 32px 32px 0px;
    position: relative;
    width: 100%;
  }
  
  .frame-credo .img {
    height: 80.13px;
    object-fit: cover;
    position: relative;
    width: 80.13px;
  }
  
  .frame-credo .description {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 1;
    flex-direction: column;
    flex-grow: 1;
    padding: 20px 32px 75px;
    position: relative;
    width: 100%;
  }
  
  .frame-credo .title {
    align-items: flex-start;
    display: flex;
    flex: 0 0 auto;
    gap: 10px;
    justify-content: center;
    margin-left: -16.88px;
    margin-right: -16.88px;
    position: relative;
    width: 275px;
  }
  
  .frame-credo .title-2 {
    color: #161543;
    flex: 1;
    font-family: "Montserrat-SemiBold", Helvetica;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1px;
    position: relative;
    text-align: center;
  }
  
  .frame-credo .text {
    align-items: flex-start;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 8px;
    padding: 10px 0px 0px;
    position: relative;
    width: 218px;
  }
  
  .frame-credo .desc {
    align-self: stretch;
    color: #7687a1;
    font-family: "Montserrat-Regular", Helvetica;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1px;
    position: relative;
    text-align: center;
  }
  
  .frame-credo .item-surface-wrapper {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 1;
    flex-direction: column;
    flex-grow: 1;
    position: relative;
  }
  
  .frame-credo .description-2 {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 1;
    flex-direction: column;
    flex-grow: 1;
    padding: 20px 32px 74px;
    position: relative;
    width: 100%;
  }
  
  .frame-credo .title-wrapper {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    gap: 10px;
    justify-content: center;
    position: relative;
    width: 100%;
  }
  
  .frame-credo .desc-wrapper {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 8px;
    padding: 10px 0px 0px;
    position: relative;
    width: 100%;
  }
  
  .frame-credo .p {
    color: #7687a1;
    font-family: "Montserrat-Regular", Helvetica;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1px;
    position: relative;
    text-align: center;
    width: 235px;
  }
  
  .frame-credo .credo-wrapper {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 1;
    flex-direction: column;
    flex-grow: 1;
    position: relative;
  }
  
  .frame-credo .item-surface-2 {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 1;
    flex-direction: column;
    flex-grow: 1;
    gap: 32px;
    position: relative;
    width: 100%;
  }
  
  .frame-credo .guarantee-wrapper {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    position: relative;
  }
  
  .frame-credo .description-3 {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 1;
    flex-direction: column;
    flex-grow: 1;
    padding: 20px 32px 52px;
    position: relative;
    width: 100%;
  }
  
  .frame-credo .title-3 {
    align-items: flex-start;
    display: flex;
    flex: 0 0 auto;
    gap: 10px;
    justify-content: center;
    margin-left: -3.88px;
    margin-right: -3.88px;
    position: relative;
    width: 249px;
  }
  
  .frame-credo .title-4 {
    color: #161543;
    flex: 1;
    font-family: "Montserrat-SemiBold", Helvetica;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 21.6px;
    margin-top: -1px;
    position: relative;
    text-align: center;
  }
  
  .frame-credo .on-time-wrapper {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    position: relative;
  }
  
  .frame-credo .description-4 {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 1;
    flex-direction: column;
    flex-grow: 1;
    padding: 20px 32px 97px;
    position: relative;
    width: 100%;
  }
  
  .frame-credo .text-2 {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 8px;
    margin-bottom: -6.13px;
    padding: 10px 0px 0px;
    position: relative;
    width: 100%;
  }
  
  
  @media screen and (max-width:600px){
    .frame-credo {
      background-color: #ffffff;
      /* height: 694px; */
      width: 100%;
    }
    
    .frame-credo .credo {
      /* height: 525px; */
      /* left: 80px; */
      position: relative;
      top: 120px;
      width: 90%;
      margin-left: 4%;
    }
    
    .frame-credo .credo-2 {
      align-items: center;
      display: inline-flex;
      flex-direction: column;
      /* gap: px; */
      /* left: 326px; */
      width: 90%;
      text-align: center;
      margin-left: 5%;
      /* position: absolute; */
      top: 0;
    }
    
    .frame-credo .reimagine-home {
      color: transparent;
      font-family: "Montserrat-Bold", Helvetica;
      font-size: 28px;
      font-weight: 700;
      letter-spacing: 0;
      line-height: normal;
      margin-top: -1px;
      position: relative;
      width: fit-content;
    }
    
    .frame-credo .text-wrapper {
      color: #f14949;
    }
    
    .frame-credo .span {
      color: #404040;
    }
    
    .frame-credo .a-step-by-step {
      color: #050505;
      font-family: "Montserrat-Regular", Helvetica;
      font-size: 18px;
      font-weight: 400;
      letter-spacing: 0;
      line-height: normal;
      position: relative;
      text-align: center;
      width: 90%;
  
    }
    
    .frame-credo .surface {
      align-items: center;
      display: inline-flex;
      flex-direction: column;
      justify-content: center;
      left: -1px;
      /* position: absolute; */
      top: 146px;
      width: 100%;
    }
    
    .frame-credo .list {
      align-items: flex-start;
      display: flex;
      flex-direction: column;
      gap: 20px;
      /* height: 321px; */
      position: relative;
      width: 100%;
    }
    
    .frame-credo .row {
      align-items: flex-start;
      align-self: stretch;
      display: flex;
      gap: 50px;
      /* height: 321px; */
      position: relative;
      width: 100%;
      flex-wrap: wrap;
      margin-top: 50px;
    }
    
    .frame-credo .block {
      align-items: flex-start;
      display: flex;
      flex: 1;
      flex-direction: column;
      flex-grow: 1;
      gap: 32px;
      height: unset !important;
      position: relative;
    }
    
    .frame-credo .item-surface {
      align-items: flex-start;
      align-self: stretch;
      display: flex;
      flex: 1;
      flex-direction: column;
      flex-grow: 1;
      gap: 32px;
      position: relative;
      width: 100%;
    }
    
    .frame-credo .image {
      align-items: flex-start;
      align-self: stretch;
      display: flex;
      flex: 0 0 auto;
      gap: 10px;
      justify-content: center;
      padding: unset !important;
      position: relative;
      width: 100%;
    }
    
    .frame-credo .img {
      height: 80.13px;
      object-fit: cover;
      position: relative;
      width: 80.13px;
    }
    
    .frame-credo .description {
      align-items: center;
      align-self: stretch;
      display: flex;
      flex: 1;
      flex-direction: column;
      flex-grow: 1;
      padding: unset !important;
      position: relative;
      width: 100%;
    }
    
    .frame-credo .title {
      align-items: flex-start;
      display: flex;
      flex: 0 0 auto;
      gap: 10px;
      justify-content: center;
      margin-left: -16.88px;
      margin-right: -16.88px;
      position: relative;
      width: 275px;
    }
    
    .frame-credo .title-2 {
      color: #161543;
      flex: 1;
      font-family: "Montserrat-SemiBold", Helvetica;
      font-size: 18px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: normal;
      margin-top: -1px;
      position: relative;
      text-align: center;
    }
    
    .frame-credo .text {
      align-items: flex-start;
      display: flex;
      flex: 0 0 auto;
      flex-direction: column;
      gap: 8px;
      padding: 10px 0px 0px;
      position: relative;
      width: 218px;
    }
    
    .frame-credo .desc {
      align-self: stretch;
      color: #7687a1;
      font-family: "Montserrat-Regular", Helvetica;
      font-size: 14px;
      font-weight: 400;
      letter-spacing: 0;
      line-height: normal;
      margin-top: -1px;
      position: relative;
      text-align: center;
    }
    
    .frame-credo .item-surface-wrapper {
      align-items: flex-start;
      align-self: stretch;
      display: flex;
      flex: 1;
      flex-direction: column;
      flex-grow: 1;
      position: relative;
    }
    
    .frame-credo .description-2 {
      align-items: flex-start;
      align-self: stretch;
      display: flex;
      flex: 1;
      flex-direction: column;
      flex-grow: 1;
      padding: unset !important;
      position: relative;
      width: 100%;
    }
    
    .frame-credo .title-wrapper {
      align-items: flex-start;
      align-self: stretch;
      display: flex;
      flex: 0 0 auto;
      gap: 10px;
      justify-content: center;
      position: relative;
      width: 100%;
    }
    
    .frame-credo .desc-wrapper {
      align-items: center;
      align-self: stretch;
      display: flex;
      flex: 0 0 auto;
      flex-direction: column;
      gap: 8px;
      padding: 10px 0px 0px;
      position: relative;
      width: 100%;
    }
    
    .frame-credo .p {
      color: #7687a1;
      font-family: "Montserrat-Regular", Helvetica;
      font-size: 14px;
      font-weight: 400;
      letter-spacing: 0;
      line-height: normal;
      margin-top: -1px;
      position: relative;
      text-align: center;
      width: 235px;
    }
    
    .frame-credo .credo-wrapper {
      align-items: center;
      align-self: stretch;
      display: flex;
      flex: 1;
      flex-direction: column;
      flex-grow: 1;
      position: relative;
    }
    
    .frame-credo .item-surface-2 {
      align-items: center;
      align-self: stretch;
      display: flex;
      flex: 1;
      flex-direction: column;
      flex-grow: 1;
      gap: 32px;
      position: relative;
      width: 100%;
    }
    
    .frame-credo .guarantee-wrapper {
      align-items: flex-start;
      display: inline-flex;
      flex: 0 0 auto;
      flex-direction: column;
      position: relative;
    }
    
    .frame-credo .description-3 {
      align-items: center;
      align-self: stretch;
      display: flex;
      flex: 1;
      flex-direction: column;
      flex-grow: 1;
      padding: unset !important;
      position: relative;
      width: 100%;
    }
    
    .frame-credo .title-3 {
      align-items: flex-start;
      display: flex;
      flex: 0 0 auto;
      gap: 10px;
      justify-content: center;
      margin-left: -3.88px;
      margin-right: -3.88px;
      position: relative;
      width: 249px;
    }
    
    .frame-credo .title-4 {
      color: #161543;
      flex: 1;
      font-family: "Montserrat-SemiBold", Helvetica;
      font-size: 18px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 21.6px;
      margin-top: -1px;
      position: relative;
      text-align: center;
    }
    
    .frame-credo .on-time-wrapper {
      align-items: center;
      display: inline-flex;
      flex: 0 0 auto;
      flex-direction: column;
      position: relative;
    }
    
    .frame-credo .description-4 {
      align-items: center;
      align-self: stretch;
      display: flex;
      flex: 1;
      flex-direction: column;
      flex-grow: 1;
      padding: unset !important;
      position: relative;
      width: 100%;
      margin-bottom: 100px;
    }
    
    .frame-credo .text-2 {
      align-items: flex-start;
      align-self: stretch;
      display: flex;
      flex: 0 0 auto;
      flex-direction: column;
      gap: 8px;
      margin-bottom: -6.13px;
      padding: 10px 0px 0px;
      position: relative;
      width: 100%;
    }
    
  }