.nav {
    height: 50px;
    /* width: 100%; */
    display: flex;
    justify-content: space-between;
    background-color: #FFF;
    position: relative;
    /* filter: drop-shadow(-6px 2px 7px grey); */
    padding:  10px 20px 10px 20px;
 
  }
  .headlinkstyle{
    color: #4A4A4A;

font-family: Montserrat;
font-size: 1rem;
font-style: normal;
font-weight: 600;
line-height: normal;
  }
  .nav > .nav-header {
    display: flex;
  }
  
  .nav > .nav-header > .nav-title {
    display: flex;
    font-size: 22px;
    color: #fff;
    padding: 10px 10px 10px 10px;
  }
  
  .nav > .nav-btn {
    display: none;
  }
  
  .nav > .nav-links {
    display: flex;
    float: right;
    font-size: 18px;
  }
  
  .nav > .nav-links > a {
    display: flex;
    padding: 16px 10px 13px 35px;
    text-decoration: none;
    /* color: black; */
    color: #4A4A4A;

font-family: Montserrat;
font-size: 1rem;
font-style: normal;
font-weight: 600;
line-height: normal;
  }
  
  /* .nav > .nav-links > a:hover {
    background-color: rgba(0, 0, 0, 0.3);
  } */
  
  .nav > #nav-check {
    display: none;
  }
  
  @media (max-width:900px) {
    .nav{
      padding-bottom: 0;
    }
    .nav > .nav-btn {
      display: inline-block;
      position: absolute;
      right: 0px;
      top: 0px;
      height: 46px;
      width: 3px;
    }
    .nav > .nav-btn > label {
      display: inline-block;
      width: 50px;
      height: 50px;
      /* padding: 13px; */
      padding-top: 10px;
      margin-left: -7px;
    }
    /* .nav > .nav-btn > label:hover,.nav  #nav-check:checked ~ .nav-btn > label {
      background-color: rgba(0, 0, 0, 0.3);
    } */
    .nav > .nav-btn > label > span {
      display: block;
      width: 25px;
      height: 10px;
      border-top: 2px solid #eee;
    }
    .nav > .nav-links {
      position: absolute;
      display: block;
      width: 100%;
      color: white I !important;
      /* background-color: #333; */
      background-color: #FFF;
      height: 0px;
      transition: all 0.3s ease-in;
      overflow-y: hidden;
      top: 70px;
      left: 0px;
    }
    .nav > .nav-links > a {
      display: block;
      width: 100%;
    }
    .nav > #nav-check:not(:checked) ~ .nav-links {
      height: 0px;
    }
    .nav > #nav-check:checked ~ .nav-links {
      height: fit-content;
      overflow-y: auto;
    }
  }
  .nav-btn{
    display: flex;
padding: 0.625rem 1rem;
justify-content: center;
align-items: center;
gap: 0.625rem;
background: #F14A4A;
color: white !important;
padding: 12px 36px 12px 36px !important;
  }